body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "PingFang SC",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

header {
  position: fixed;
  z-index: 1000;
  height: 64px;
  display: flex;
  justify-content: center;
  width: 100%;
  transition: box-shadow 0.3s ease-out, background 0.3s ease-out;
}
header .headerWrapper {
  width: 70%;
  max-width: 1200px;
  height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
header .headerWrapper .logoBox {
  flex: 1 1;
  display: flex;
  align-items: center;
}
header .headerWrapper .logoBox img {
  width: 14.2vw;
  max-width: 200px;
}
header .headerWrapper .menuBox {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 64px;
}
header .phoneHeader {
  width: 82%;
  max-width: 100%;
  height: 10vw;
}
header .phoneHeader .logoBox {
  font-size: 4vw;
}
header .phoneHeader .logoBox img {
  width: 40vw;
  margin-right: 2vw;
}
header .phoneHeader .nav-phone-menu {
  fill: #fff;
  width: 4vw;
  height: 4vw;
  cursor: pointer;
}
header .button {
  line-height: 64px;
}
header .button .ant-btn {
  border-radius: 10px;
  font-size: 16px;
  line-height: 32px;
  height: 32px;
  color: #fff;
  border-width: 0;
  font-family: PingFang SC;
  background-color: transparent;
  box-shadow: 0 0 0;
}
header .button .ant-btn:hover {
  background-color: rgba(34, 83, 163, 0.3);
  color: #fff;
}
header .splitters {
  margin: 0 1.1vw;
  height: 17px;
  width: 1px;
  border-left: 1px solid #fff;
}
header .active-btn {
  float: right;
  height: 100%;
  line-height: 64px;
}
header .active-btn .ant-btn {
  border-radius: 10px;
  font-size: 16px;
  line-height: 32px;
  height: 32px;
  color: #fff;
  border-width: 0;
  font-family: PingFang SC;
  background-color: #2253A3;
  box-shadow: 0 0 0;
}
header .active-btn .ant-btn:hover {
  background-color: rgba(245, 191, 76, 0.7);
  color: #fff;
}
header.show-shadow {
  background: #FDFDFD;
  box-shadow: 0 2px 8px rgba(229, 229, 229, 0.5);
}
header.btn-white {
  background-color: #FFF;
  box-shadow: 0 2px 8px rgba(229, 229, 229, 0.5);
}
header.btn-white .headerWrapper .logoBox {
  color: #2253A3;
}
header.btn-white .headerWrapper .splitters {
  border-left: 1px solid #2253A3;
}
header.btn-white .button .ant-btn {
  color: #2253A3;
}
header.btn-white .button .ant-btn:hover {
  background-color: rgba(34, 83, 163, 0.3);
  color: #2253A3;
}
header.btn-white .active-btn .ant-btn {
  background-color: #2253A3;
}
header.btn-white .active-btn .ant-btn:hover {
  background-color: rgba(34, 83, 163, 0.3);
  color: #2253A3;
}
header.btn-white .nav-phone-menu {
  fill: #333;
}
.popover-menu {
  width: 40vw;
  left: 27vw;
}
.popover-menu button.lang {
  margin: 16px auto;
  float: none;
}
.popover-menu div.version {
  margin: 32px auto 16px;
  float: none;
}
.popover-menu .ant-popover-inner {
  overflow: hidden;
}
.popover-menu .ant-popover-inner-content {
  padding: 0;
}
.popover-menu .ant-popover-content ul li {
  padding: 0;
}
.popover-menu .ant-popover-content ul li span {
  color: #2253A3;
}
.popover-menu .ant-popover-content ul li:hover {
  background: #2bc0a14d;
}
.popover-menu .ant-popover-content ul li::selection {
  background: #2253A3;
  color: #fff;
}
.popover-menu .ant-popover-content ul .ant-menu-item-selected {
  background: #2bc0a14d;
  color: #fff;
}
.popover-menu .ant-popover-content ul .ant-menu-item::after {
  border-right: 4px solid #2253A3;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors */
.text-center {
  text-align: center!important;
}
.text-left {
  text-align: left!important;
}
#reat-content {
  font-family: Roboto, PingFang SC, Helvetica Neue For Number, -apple-system, BlinkMacSystemFont, Segoe UI, Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial, sans-serif;
}
.intro .header {
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
}
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
}
.btn {
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.page-wrapper {
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.card {
  text-decoration: none;
}
.card .title,
.card .description {
  color: rgba(0, 0, 0, 0.65) !important;
  font-size: 14px;
  font-weight: 300;
}
.card:hover .title {
  color: rgba(0, 0, 0, 0.65) !important;
}
.features img {
  margin-top: 0 !important;
}
.page h2,
.page h4,
.page h5,
.page span,
.page p,
.page .feature,
.page .card-wrapper,
.page .main-info,
.page .detail {
  will-change: transform;
}
.page .main-info {
  line-height: 36px;
}
.banner {
  width: 100%;
  margin: auto;
  position: relative;
  height: 100vh;
  overflow: hidden;
}
.banner-wrapper {
  background: #f8fafe;
  min-height: 320px;
}
.banner > div {
  display: inline-block;
}
.banner-bg-img {
  width: 100%;
  height: 100vh;
}
.banner-bg-word-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  padding-top: 196px;
  overflow: hidden;
}
.banner-word-main {
  width: 70%;
  margin: 0 auto;
  max-width: 1200px;
  display: flex;
  justify-content: space-between;
}
.banner-word-left {
  flex: 3 1;
}
.banner-word-left-title {
  padding: 0;
  margin: 0;
  width: 400px;
  font-size: 33px;
  font-family: PingFang SC;
  font-weight: 500;
  line-height: 46px;
  color: #FFFFFF;
}
.banner-word-left-subtitle {
  padding: 0;
  margin: 39px 0 16px 0;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  line-height: 20px;
  color: #FFFFFF;
}
.banner-word-left-desc {
  padding: 0;
  margin: 0;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  line-height: 20px;
  color: #FFFFFF;
}
.banner-word-download {
  margin-top: 53px;
  width: 195px;
  height: 44px;
  background: #F5BF4C;
  border-radius: 16px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 600;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.banner-word-download svg {
  width: 17px;
  height: 17px;
  fill: #fff;
  margin-right: 10px;
}
.banner-word-download:hover {
  background: rgba(245, 191, 76, 0.7);
  color: #fff;
}
.banner-word-img-wrapper {
  flex: 4 1;
  padding: 88px;
}
.banner-word-img-wrapper img {
  width: 417px;
  height: 336px;
}
.banner-img-wrapper {
  width: 60%;
  height: 100%;
}
.banner-text-wrapper {
  width: 40%;
  padding-right: 114px;
  height: 265px;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
}
.banner-text-wrapper h1 {
  font-size: 46px;
}
.banner-text-wrapper p {
  font-size: 16px;
  margin: 50px 0 60px;
  font-weight: 300;
}
.banner-wrapper {
  width: 100%;
  height: 56.3vw;
  background: #22186E;
  background: url(../../static/media/bannerBg.cf8a7614.png) 100% 100% / auto 100% no-repeat;
  padding-top: 15.6vw;
}
.banner-wrapper .banner-constent {
  width: 70%;
  margin: 0 auto;
}
.banner-wrapper .banner-constent .banner-desc {
  width: 20vw;
  font-size: 2.1vw;
  font-family: PingFang SC;
  font-weight: 500;
  line-height: 3.1vw;
  color: #FFFFFF;
}
.feature-wrapper {
  background: #fff;
  padding-top: 10.4vw;
  padding-bottom: 4.2vw;
}
.feature-wrapper .item-feature {
  display: flex;
  margin-bottom: 3.3vw;
}
.feature-wrapper .item-feature .leftItem {
  flex: 1 1;
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.feature-wrapper .item-feature .leftItem .featureDesc {
  width: 27vw;
  text-align: right;
}
.feature-wrapper .item-feature .marginBox {
  width: 5.2vw;
}
.feature-wrapper .item-feature .rightItem {
  flex: 1 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.feature-wrapper .item-feature .rightItem .featureDesc {
  width: 28.3vw;
  text-align: left;
}
.feature-wrapper .item-feature .cardImg {
  width: 26.1vw;
  height: 17.4vw;
}
.feature-wrapper .item-feature .cardTitle {
  font-size: 1.4vw;
  font-family: PingFang SC;
  font-weight: bold;
  line-height: 3vw;
  color: #4D4D4D;
}
.feature-wrapper .item-feature .cardDetail {
  margin-top: 0.5vw;
  font-size: 1.3vw;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 1.7vw;
  color: #4D4D4D;
}
.solution-wrapper {
  z-index: 1;
  width: 100%;
  height: 91vw;
  background: #fff;
  background: url(../../static/media/solutionBg.4becc9e2.png) 100% 100% / auto 100% no-repeat;
  padding-top: 11.6vw;
}
.solution-wrapper .title {
  font-size: 1.4vw;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 3vw;
  color: #4D4D4D;
  text-align: center;
}
.solution-wrapper .allSolution {
  margin: 0 auto;
  width: 62.9vw;
  display: flex;
  flex-wrap: wrap;
}
.solution-wrapper .allSolution .oneSoluton {
  margin-top: 3.1vw;
  width: 31.4vw;
  text-align: center;
}
.solution-wrapper .allSolution .oneSoluton img {
  width: 6.3vw;
  height: 6.3vw;
  margin-bottom: 1.6vw;
}
.solution-wrapper .allSolution .oneSoluton .solutonTitle {
  margin-bottom: 0.5vw;
  font-size: 1.3vw;
  font-family: PingFang SC;
  font-weight: 500;
  line-height: 1.7vw;
  color: #4D4D4D;
}
.solution-wrapper .allSolution .oneSoluton .solutonDetail .oneRow {
  text-align: center;
  font-size: 1vw;
  font-family: PingFang SC;
  font-weight: 500;
  line-height: 1.3vw;
  color: #4D4D4D;
}
.contact-wrapper {
  margin-top: -27.6vw;
  position: relative;
}
.contact-wrapper .title {
  font-size: 1.4vw;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 3vw;
  color: #4D4D4D;
  text-align: center;
}
.contact-wrapper .contactDetail {
  position: relative;
  z-index: 10;
  margin: 0 auto;
  width: 62.5vw;
  height: 20.8vw;
  background: #FFFFFF;
  border-radius: 1.1vw;
  display: flex;
  align-items: center;
}
.contact-wrapper .contactDetail .logoImg {
  height: 6.3vw;
  margin: 0 3.1vw 0 2.6vw;
}
.contact-wrapper .contactDetail .detailInfo {
  flex: 1 1;
  padding: 0 3.1vw;
  border-left: 1px solid #ccc;
}
.contact-wrapper .contactDetail .detailInfo .oneRow {
  display: flex;
  align-items: center;
}
.contact-wrapper .contactDetail .detailInfo .oneRow .commonIcon {
  width: 2.6vw;
  height: 2.6vw;
  margin-right: 1.6vw;
  fill: #2253A3;
}
.contact-wrapper .contactDetail .detailInfo .oneRow .emailIcon {
  width: 2.1vw;
  height: 2.1vw;
  margin-right: 1.9vw;
  fill: #2253A3;
}
.contact-wrapper .contactDetail .detailInfo .oneRow .telIcon {
  width: 2.3vw;
  height: 2.3vw;
  margin-right: 1.7vw;
  fill: #2253A3;
}
.contact-wrapper .contactDetail .detailInfo .oneRow .detail {
  flex: 1 1;
  font-size: 1.4vw;
  font-family: PingFang SC;
  font-weight: 400;
  color: #4D4D4D;
  line-height: 1.7vw;
}
.contact-wrapper .contactDetail .detailInfo .oneRow .detail .marTop {
  margin-top: 0.5vw;
}
.contact-wrapper .contactDetail .detailInfo .marginB {
  margin-bottom: 1.3vw;
  align-items: center;
}
.contact-wrapper .footerBg {
  position: relative;
  z-index: 0;
  width: 100%;
  background: #2253A3;
  height: 7.3vw;
  margin-top: -2.1vw;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors */
.footer-wrapper {
  background: #24368C;
  height: 3.4vw;
  font-size: 0.7vw;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 3.4vw;
  color: #FFFFFF;
  text-align: center;
}

